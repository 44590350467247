import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import cuid from 'cuid';
import NewArticleCard from '../../molecules/NewArticleCard';
import Icon from '../../atoms/Icon';

const ArticleList = () => {
  const [index, setIndex] = useState(4);
  const [dotIndex, setDotIndex] = useState(0);
  const MAX_DOTS = 4;

  const { allCosmicjsContentPages } = useStaticQuery(
    graphql`
      {
        allCosmicjsContentPages(limit: 17) {
          edges {
            next {
              slug
              metadata {
                slug_override
                canonical_slug
                meta_title
                meta_description
                feature_image {
                  imgix_url
                }
                website_path_info {
                  directory_path
                  parent_paths {
                    directory_path
                  }
                }
              }
              title
            }
          }
        }
      }
    `,
  );
  const articles = allCosmicjsContentPages.edges;
  return (
    <div className="container pt-12 pb-16">
      <h2 className="mb-6 text-xl sm:text-2xl">
        The latest from Anytime Estimate
      </h2>

      <ul className="grid gap-4 sm:grid-cols-4">
        {articles.slice(dotIndex * MAX_DOTS, index).map(article => (
          <li
            key={cuid()}
            className="transition-opacity ease-in duration-700 opacity-100"
          >
            <NewArticleCard article={article.next} />
          </li>
        ))}
      </ul>
      <div className="grid grid-cols-1 sm:grid-cols-12 items-center justify-end">
        <ul className="hidden sm:flex col-start-6 col-end-8 flex-row justify-center items-center gap-4">
          <button
            type="button"
            disabled={dotIndex === 0}
            tabIndex={0}
            onClick={() => {
              setIndex(index - MAX_DOTS);
              setDotIndex(dotIndex - 1);
            }}
          >
            <Icon
              type="chevronLeft"
              className={`w-[20px] h-[20px] fill-current ${
                dotIndex === 0 ? 'text-gray-300' : 'text-primary-500'
              }`}
            />
          </button>
          {Array.from({ length: MAX_DOTS }).map((_, i) => (
            <li className="h-[20px] items-center flex" key={cuid()}>
              <button
                type="button"
                onClick={() => {
                  setIndex(i * 4 + 4);
                  setDotIndex(i);
                }}
                className={`w-3 h-3 rounded-full ${
                  dotIndex === i ? 'bg-primary-500' : 'bg-gray-300'
                }`}
              />
            </li>
          ))}
          <button
            type="button"
            disabled={dotIndex === 3}
            tabIndex={0}
            onClick={() => {
              setIndex(index + MAX_DOTS);
              setDotIndex(dotIndex + 1);
            }}
          >
            <Icon
              type="chevronRight"
              className={`w-[20px] h-[20px] ${
                dotIndex === 3 ? 'text-gray-300' : 'text-primary-500'
              }`}
            />
          </button>
        </ul>
        <Link
          to="/blog"
          className="justify-center sm:col-start-8 sm:col-end-13 text-black hover:text-blue-500 flex flex-row font-bold gap-2 sm:justify-end my-12"
        >
          View all articles{' '}
          <Icon
            type="chevronRight"
            className="w-[20px] h-[20px] inline-block fill-current"
          />
        </Link>
      </div>
    </div>
  );
};

ArticleList.defaultProps = {};

ArticleList.propTypes = {};

export default ArticleList;
