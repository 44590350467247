import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cuid from 'cuid';
import Image from '../../atoms/Image';

const HomeHero = ({ subheading }) => {
  const defaultHeading = (
    <h1 className="sm:text-2xl text-xl">
      All your real estate needs,{' '}
      <span className="text-primary-500">any time, any place.</span>
    </h1>
  );

  const sections = [
    {
      heading: 'Low Commission Realtors',
      link: '/reviews/best-low-commission-real-estate-agents/',
      image:
        'https://imgix.cosmicjs.com/36ba7590-55d1-11ef-b1ea-f56c65dfade9-crowdfund.png',
    },
    {
      heading: 'Home Buyer Rebates',
      link: '/home-buying/home-buyer-rebates/',
      image:
        'https://imgix.cosmicjs.com/84c58e00-55d1-11ef-b1ea-f56c65dfade9-rebates.png',
    },
    {
      heading: 'Commission Calculator',
      link: '/home-seller-costs/real-estate-commission-calculator/',
      image:
        'https://imgix.cosmicjs.com/aba38180-55d1-11ef-b1ea-f56c65dfade9-calculator.png',
    },
    {
      heading: 'Home Sale Calculator',
      link: '/home-selling/home-sale-calculator/',
      image:
        'https://imgix.cosmicjs.com/cf7fa6b0-55d1-11ef-b1ea-f56c65dfade9-home_sale_calculator.png',
    },
  ];

  return (
    <section className="mt-12 px-4">
      <div className="mx-auto text-center max-w-[570px]">
        {defaultHeading}
        <p className="mt-6 sm:text-base text-sm">{subheading}</p>
      </div>
      <div className="grid grid-cols-2  sm:flex sm:flex-row flex-wrap gap-4 mx-auto justify-center relative top-[55px]">
        {sections.map(section => (
          <Link
            className="p-4 sm:p-0 sm:w-[242px] sm:h-[112px] flex flex-col text-center justify-center items-center rounded-lg shadow-lg text-black bg-white"
            key={cuid()}
            to={section.link}
            aria-label={section.heading}
            title={section.heading}
          >
            <Image
              src={section.image}
              alt={section.heading}
              className="sm:w-[40px] sm:h-[40px] w-[30px] h-[30px]"
              width={40}
              height={40}
            />
            <h4 className="text-xs tracking-tight mt-2 sm:text-base font-medium">
              {section.heading}
            </h4>
          </Link>
        ))}
      </div>
    </section>
  );
};

HomeHero.defaultProps = {
  subheading: '',
};

HomeHero.propTypes = {
  subheading: PropTypes.string,
};

export default HomeHero;
