import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../../atoms/Image';
import Icon from '../../atoms/Icon';

const FallbackImage =
  'https://imgix.cosmicjs.com/87071bf0-5605-11ef-b1ea-f56c65dfade9-FallbackImage.png?auto=format';
const NewArticleCard = ({ article }) => {
  const slug = useMemo(() => {
    if (!article) return null;
    const baseSlug = article.slug;
    if (article.metadata.website_path_info) {
      const parentPaths = article.metadata.website_path_info.parent_paths
        ? article.metadata.website_path_info.parent_paths
            .map(path => path.directory_path)
            .join('') + baseSlug
        : baseSlug.slice(1);
      return parentPaths.slice(1);
    }
    return `${baseSlug}`;
  }, [article]);

  if (!article) return null;

  return (
    <Link
      to={slug}
      className="bg-white rounded-lg shadow-md flex flex-col gap-4 p-4 sm:h-[290px]"
    >
      <Image
        src={article?.metadata?.feature_image?.imgix_url ?? FallbackImage}
        alt={article.title}
        height={107}
        width={210}
        className="w-full h-36 sm:h-auto sm:min-h-32 object-cover object-center rounded-lg overflow-hidden"
      />
      <div className="flex flex-col justify-between h-full">
        <h2 className="text-base font-semibold text-gray-800 tracking-tight">
          {article.title}
        </h2>
        <p className="mt-4 text-blue-500 hover:underline flex flex-row gap-3 items-center justify-end">
          Read more <Icon type="chevronRight" className="fill-current" />
        </p>
      </div>
    </Link>
  );
};

NewArticleCard.defaultProps = {
  article: {},
};

NewArticleCard.propTypes = {
  article: PropTypes.shape({
    title: '',
    slug: '',
    metadata: {
      feature_image: {
        imgix_url: '',
      },
      canonical_slug: '',
      website_path_info: {
        directory_path: '',
        parent_paths: {
          directory_path: '',
        },
      },
    },
  }),
};

export default NewArticleCard;
