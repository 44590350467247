import React from 'react';
import cuid from 'cuid';
import Image from '../../atoms/Image';

const AsSeenIn = () => {
  const companies = [
    {
      logo: 'https://imgix.cosmicjs.com/259b7030-55da-11ef-b1ea-f56c65dfade9-Business_Insider_Logo-1.png',
      alt: 'Business Insider',
      width: 98,
      height: 31,
    },
    {
      logo: 'https://imgix.cosmicjs.com/3e595c90-55da-11ef-b1ea-f56c65dfade9-CNBC_logo-1.png',
      alt: 'CNBC',
      width: 61,
      height: 49,
    },
    {
      logo: 'https://imgix.cosmicjs.com/55214550-55da-11ef-b1ea-f56c65dfade9-Fox_Business-1.png',
      alt: 'Fox Business',
      width: 81,
      height: 40,
    },
    {
      logo: 'https://imgix.cosmicjs.com/6ad01cf0-55da-11ef-b1ea-f56c65dfade9-Fast_Company_logo-1.png',
      alt: 'Fast Company',
      width: 185,
      height: 26,
    },
    {
      logo: 'https://imgix.cosmicjs.com/82acf820-55da-11ef-b1ea-f56c65dfade9-NewYorkTimes-1.png',
      alt: 'New York Times',
      width: 230,
      height: 30,
    },
    {
      logo: 'https://imgix.cosmicjs.com/97c05310-55da-11ef-b1ea-f56c65dfade9-Yahoo_Finance_logo_2021-1.png',
      alt: 'Yahoo Finance',
      width: 110,
      height: 40,
    },
    {
      logo: 'https://imgix.cosmicjs.com/ad25ecb0-55da-11ef-b1ea-f56c65dfade9-National_Public_Radio_logo-1.png',
      alt: 'National Public Radio',
      width: 103,
      height: 33,
    },
    {
      logo: 'https://imgix.cosmicjs.com/c9620350-55da-11ef-b1ea-f56c65dfade9-MarketWatch.png',
      alt: 'MarketWatch',
      width: 142,
      height: 20,
    },
  ];
  return (
    <section className="pt-20 sm:pt-0 my-12 bg-white px-4">
      <div className="flex flex-col justify-center items-center text-center gap-4 w-full mx-auto">
        <h3 className="text-xl sm:text-2xl font-semibold">As Seen In</h3>
        <div className="grid grid-cols-2 justify-center items-center place-items-center sm:flex sm:flex-row sm:flex-wrap gap-8">
          {companies.map(company => (
            <Image
              key={cuid()}
              src={company.logo}
              alt={company.alt}
              width={company.width}
              height={company.height}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

AsSeenIn.defaultProps = {};

AsSeenIn.propTypes = {};

export default AsSeenIn;
