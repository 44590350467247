import React from 'react';
import { Link } from 'gatsby';
import cuid from 'cuid';
import Image from '../../atoms/Image';

const RealEstateTools = () => {
  const HomeSellerCalculators = [
    {
      heading: 'Real estate commission calculator',
      subtitle:
        "The commission calculator helps you estimate the total amount you'll pay in realtor fees when you sell.",
      link: '/home-seller-costs/real-estate-commission-calculator/',
      image:
        'https://imgix.cosmicjs.com/aebba910-55f4-11ef-b1ea-f56c65dfade9-8611103_crowdfunding_business_finance_funding_money_icon-1.png',
    },
    {
      heading: 'How much can I sell my house for?',
      subtitle:
        'The home sale proceeds calculator will help determine how much the house should sell for.',
      link: '/home-seller-costs/how-much-can-i-sell-my-house-for-in-pa/',
      image:
        'https://imgix.cosmicjs.com/aeccc010-55f4-11ef-b1ea-f56c65dfade9-8569541_banking_bank_finance_business_financial_icon-1-1.png',
    },
    {
      heading: 'Home sale calculator',
      subtitle:
        'Use our home sale calculator to calculate how much you could earn by selling your home.',
      link: '/home-selling/home-sale-calculator/',
      image:
        'https://imgix.cosmicjs.com/aebc6c60-55f4-11ef-b1ea-f56c65dfade9-8569557_banking_bank_finance_business_financial_icon-5-1.png',
    },
  ];

  const HomeBuyerCalculators = [
    {
      heading: 'DTI calculator',
      subtitle:
        'You can use our debt-to-income ratio calculator to see how your DTI matches up to the 28/36% rule.',
      link: '/mortgage/mortgage-debt-to-income-ratio/',
      image:
        'https://imgix.cosmicjs.com/aeb9fb60-55f4-11ef-b1ea-f56c65dfade9-8667362_management_business_office_company_business-management_icon-1.png',
    },
    {
      heading: 'Extra mortgage payment calculator',
      link: '/extrapayments/',
      subtitle:
        'See how fast you can pay off your mortgage with additional monthly payments.',
      image:
        'https://imgix.cosmicjs.com/aec91690-55f4-11ef-b1ea-f56c65dfade9-8569548_banking_bank_finance_business_financial_icon-1.png',
    },
    {
      heading: 'FHA Mortgage Calculator',
      link: '/fha-loans/fha-mortgage-insurance-calculator/',
      image:
        'https://imgix.cosmicjs.com/aecdaa70-55f4-11ef-b1ea-f56c65dfade9-8667384_management_business_office_company_business-management_icon-1.png',
      subtitle:
        'Easily calculate the FHA mortgage, funding Fee & the monthly mortgage insurance fees.',
    },
  ];

  return (
    <section className="mt-12 px-4 bg-[#f3f5f9] py-12">
      <div className="flex items-start sm:items-center justify-start sm:justify-center flex-col mb-8">
        <h4 className="text-xl sm:text-2xl text-left sm:text-center">
          Real Estate Tools
        </h4>
        <p className="text-sm sm:text-base">
          Use the tools below to crunch the numbers throughout your real estate
          journey.
        </p>
      </div>
      <div className="max-w-[1000px] w-full mx-auto">
        <h5 className="mb-4 text-lg">Home seller calculators</h5>
        <div className="grid grid-cols-1  sm:grid sm:grid-cols-3 gap-4 mx-auto justify-center sm:justify-start mb-8">
          {HomeSellerCalculators.map(section => (
            <Link
              className="p-6 flex flex-col gap-3 text-center justify-center items-center sm:items-start rounded-2xl shadow-lg text-black bg-white"
              key={cuid()}
              to={section.link}
              aria-label={section.heading}
              title={section.heading}
            >
              <div className="flex flex-col sm:flex-row gap-3 items-center justify-start sm:h-[56px]">
                <Image
                  src={section.image}
                  alt={section.heading}
                  className="w-[48px] h-[48px]"
                  width={48}
                  height={48}
                />
                <h4 className="text-sm tracking-tight mt-2 sm:text-base font-medium text-left text-primary-500 underline">
                  {section.heading}
                </h4>
              </div>
              <p className="text-sm sm:text-base sm:text-left">
                {section.subtitle}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <div className="max-w-[1000px] w-full mx-auto">
        <h5 className="mb-4 text-lg">Home buyer calculators</h5>
        <div className="grid grid-cols-1  sm:grid sm:grid-cols-3 gap-4 mx-auto justify-center sm:justify-start mb-8">
          {HomeBuyerCalculators.map(section => (
            <Link
              className="p-4 flex flex-col gap-3 text-center justify-center items-center sm:items-start rounded-2xl shadow-lg text-black bg-white"
              key={cuid()}
              to={section.link}
            >
              <div className="flex flex-col sm:flex-row gap-3 items-center justify-start sm:h-[56px]">
                <Image
                  src={section.image}
                  alt={section.heading}
                  className="w-[48px] h-[48px]"
                  width={48}
                  height={48}
                />
                <h4 className="text-sm tracking-tight mt-2 sm:text-base font-medium text-left text-primary-500 underline">
                  {section.heading}
                </h4>
              </div>
              <p className="text-sm sm:text-base sm:text-left">
                {section.subtitle}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

RealEstateTools.defaultProps = {};

RealEstateTools.propTypes = {};

export default RealEstateTools;
